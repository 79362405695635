export default [
  {
    name: "Invoices",
    path: "/invoice",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
  {
    name: "View Invoice",
    path: "/invoice/view",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
  {
    name: "Invoice transactions and settlements",
    path: "/invoice/:id/transaction-settlement-list",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/TransactionWithSettlementList"),
  },
  {
    name: "Bank pay",
    path: "/invoice/bank-pay",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/BankPay"),
  },
];
