export default [
  {
    name: "MDR Setup",
    path: "/invoice_generator/mdr_setup",
    meta: {
      layout: "Plain",
    },
    component: () => import("@/modules/mdr/Pages/Form"),
  },
];
