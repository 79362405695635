export const ID = "_id";
export const IRI_ID = "id";

export const TITLE = "title";
export const PAYER_COMPANY = "payerCompany";

export const CARD_TYPE_ID = "cardTypeId";
export const REGION_TYPE_ID = "regionTypeId";
export const AMOUNT_PERCENTAGE = "amountPercentage";
export const AMOUNT_FIX = "amountFix";

export const COMPANY = "company";
export const PROCESSING_ACCOUNTS = "processingAccounts";
export const TERMINAL_CURRENCY = "terminalCurrency";
export const TERMINAL_STATUS = "terminalEnabled";
export const TERMINALS = "terminals";

export const PDF_PROCESSING_TEMPLATE = "pdfProcessingTemplate";

export const BENEFICIARY_COMPANY = "beneficiaryCompany";
export const BENEFICIARY_TYPE = "beneficiaryTypeId";

export const GENERATOR_TIME = "generatorTime";
export const GENERATOR_ACTIVATION = "generatorEnabled";
export const GENERATOR_LOCKED = "locked";
export const GENERATOR_STATE = "state";
export const GENERATOR_LOGO = "logo";
export const GENERATOR_ACTIVATION_FROM = "generatorEnabledFrom";
export const GENERATOR_ACTIVATION_TO = "generatorEnabledTo";

export const EMAIL_ENABLED = "emailEnabled";
export const EMAIL_RECIPIENTS = "emailRecipient";
export const EMAIL_TEMPLATE = "emailTemplate";

export const ALLOW_ZERO = "zeroInvoiceEnabled";

export const INVOICE_PERIOD = "invoicePeriod";
export const INVOICE_PERIOD_TEXT = "invoicePeriodText";
export const EXPECTED_PAYMENT = "expectedPayment";

export const SETTLEMENT_CURRENCY = "settlementCurrency";
export const EXCHANGE_FEE_PERCENTAGE = "exchangeFeePercentage";
export const FEE_CURRENCY = "feeCurrency";

export const ROLLING_ENABLED = "rollingEnabled";
export const ROLLING_PERCENTAGE = "rollingPercentage";
export const ROLLING_DELAY = "rollingDelay";

export function structure() {
  return {
    select: [
      COMPANY,
      PAYER_COMPANY,
      TERMINAL_CURRENCY,
      SETTLEMENT_CURRENCY,
      FEE_CURRENCY,
      BENEFICIARY_COMPANY,
      EMAIL_TEMPLATE,
    ],
    multi: [PROCESSING_ACCOUNTS, TERMINALS],
    flat: [
      ID,
      IRI_ID,
      TITLE,
      TERMINAL_STATUS,
      PDF_PROCESSING_TEMPLATE,
      BENEFICIARY_TYPE,
      EMAIL_ENABLED,
      ALLOW_ZERO,
      EMAIL_RECIPIENTS,
      GENERATOR_TIME,
      GENERATOR_ACTIVATION,
      GENERATOR_ACTIVATION_FROM,
      GENERATOR_ACTIVATION_TO,
      INVOICE_PERIOD,
      INVOICE_PERIOD_TEXT,
      GENERATOR_ACTIVATION,
      EXPECTED_PAYMENT,
      EXCHANGE_FEE_PERCENTAGE,
      ROLLING_ENABLED,
      ROLLING_PERCENTAGE,
      ROLLING_DELAY,
      GENERATOR_LOCKED,
      GENERATOR_STATE,
      GENERATOR_LOGO,
    ],
  };
}

export default {
  data() {
    return {
      TITLE,
      PAYER_COMPANY,
      CARD_TYPE_ID,
      REGION_TYPE_ID,
      AMOUNT_PERCENTAGE,
      AMOUNT_FIX,
      COMPANY,
      PROCESSING_ACCOUNTS,
      TERMINAL_CURRENCY,
      SETTLEMENT_CURRENCY,
      FEE_CURRENCY,
      TERMINAL_STATUS,
      TERMINALS,
      PDF_PROCESSING_TEMPLATE,
      BENEFICIARY_COMPANY,
      BENEFICIARY_TYPE,
      GENERATOR_TIME,
      GENERATOR_ACTIVATION,
      GENERATOR_ACTIVATION_FROM,
      GENERATOR_ACTIVATION_TO,
      EMAIL_ENABLED,
      EMAIL_RECIPIENTS,
      EMAIL_TEMPLATE,
      ALLOW_ZERO,
      INVOICE_PERIOD,
      EXPECTED_PAYMENT,
      EXCHANGE_FEE_PERCENTAGE,
      ROLLING_ENABLED,
      ROLLING_PERCENTAGE,
      ROLLING_DELAY,
      GENERATOR_LOCKED,
      GENERATOR_STATE,
      GENERATOR_LOGO,
    };
  },
};
