import connection from "../connection";
import { print } from "graphql";
import listQuery from "../graphql/queries/cardProductType.graphql";

export default {
  /**
   * @returns {Array|Boolean}
   */
  getList() {
    return connection.graph({
      query: print(listQuery),
    });
  },
};
