import connection from "../connection";
import { print } from "graphql";
import customFees from "../graphql/queries/customFees.graphql";
import createCustomFee from "../graphql/mutations/createCustomFee.graphql";
import deleteCustomFee from "../graphql/mutations/deleteCustomFee.graphql";

export default {
  /**
   * @param invoiceGenerator_id
   * @returns {Array|Boolean}
   */
  readCollection(invoiceGenerator_id) {
    return connection.graph({
      query: print(customFees),
      variables: { invoiceGenerator_id },
    });
  },
  /**
   * @param customFeeType
   * @returns {Array|Boolean}
   */
  createCollection(customFeeType) {
    return connection.graph({
      query: print(createCustomFee),
      variables: { input: { ...customFeeType } },
    });
  },
  /**
   * @param deleteCustomFeeInput
   * @returns {Array|Boolean}
   */
  deleteRecord(deleteCustomFeeInput) {
    return connection.graph({
      query: print(deleteCustomFee),
      variables: { input: { id: deleteCustomFeeInput } },
    });
  },
};
