export default [
  {
    name: "Create manual invoice",
    path: "/manual_invoice_generator/new",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Form"),
  },
];
