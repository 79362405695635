import Vue from "vue";
import Vuex from "vuex";
import camelCase from "lodash/camelCase";

Vue.use(Vuex);

const requireModule = require.context(
  "../modules",
  true,
  /Store\/module.*(js)$/i
);
const modules = {};

requireModule.keys().forEach((fileName) => {
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ""));
  modules[moduleName] = {
    ...requireModule(fileName).default,
  };
});

export default new Vuex.Store({
  modules: modules,
  strict: "production" !== process.env.NODE_ENV,
});
