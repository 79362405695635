import connection from "../connection";
// import gql from "graphql-tag";
import mdrs from "../graphql/queries/mdrs.graphql";
import createMdr from "../graphql/mutations/createMdr.graphql";
import deleteMdr from "../graphql/mutations/deleteMdr.graphql";
import { print } from "graphql";

export default {
  /**
   * @param invoiceGenerator_id
   * @returns {Array|Boolean}
   */
  readCollection(invoiceGenerator_id) {
    return connection.graph({
      query: print(mdrs),
      variables: { invoiceGenerator_id },
    });
  },
  /**
   * @param mdr
   * @returns {Array|Boolean}
   */
  createCollection(mdr) {
    return connection.graph({
      query: print(createMdr),
      variables: { input: { ...mdr } },
    });
  },
  /**
   * @param invoiceGeneratorIriId
   * @returns {Array|Boolean}
   */
  deleteCollection(invoiceGeneratorIriId) {
    return connection.graph({
      query: print(deleteMdr),
      variables: { id: invoiceGeneratorIriId },
    });
  },
};
