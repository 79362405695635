export const READ_FEE_SETUP_COLLECTION = "READ_FEE_SETUP_COLLECTION";
export const CREATE_FEE_SETUP_COLLECTION = "CREATE_FEE_SETUP_COLLECTION";

export const RESET_SETUP_FEE = "RESET_SETUP_FEE";

export const UPDATE_FEE_SETUP_DEBIT_COLLECTION =
  "UPDATE_FEE_SETUP_DEBIT_COLLECTION";
export const UPDATE_FEE_SETUP_CREDIT_COLLECTION =
  "UPDATE_FEE_SETUP_CREDIT_COLLECTION";

export const UPDATE_FEE_SETUP_DEBIT_COUNTER = "UPDATE_FEE_SETUP_DEBIT_COUNTER";
export const UPDATE_FEE_SETUP_CREDIT_COUNTER =
  "UPDATE_FEE_SETUP_CREDIT_COUNTER";
