export default [
  {
    name: "Payments",
    path: "/payment",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
  {
    name: "View Payment",
    path: "/payment/show/:id(\\d+)",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Show"),
  },
];
