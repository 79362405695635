import connection from "../connection";
import { print } from "graphql";
import feeUseTypes from "../graphql/queries/feeUseTypes.graphql";

export default {
  /**
   * @param invoiceGenerator_id
   * @returns {Array|Boolean}
   */
  readCollection() {
    return connection.graph({
      query: print(feeUseTypes),
    });
  },
};
