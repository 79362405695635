import connection from "../connection";
import { print } from "graphql";
import logHistories from "../graphql/queries/logHistories.graphql";
import logHistoryFilter from "../graphql/queries/logHistoryFilter.graphql";
import logHistory from "../graphql/queries/logHistory.graphql";

export default {
  /**
   * @returns {Array|Boolean}
   */
  getCollection(variables) {
    if (!variables.page || variables.page == 0) {
      delete variables.page;
      delete variables.pageSize;
    }
    return connection.graph({
      query: print(logHistories),
      variables: {
        ...variables,
        ...(variables.page
          ? {
              page: btoa((variables.page - 1) * variables.pageSize - 1),
              first: variables.pageSize,
            }
          : {}),
        ...(variables.createdAtFrom
          ? {
              createdAtFrom: variables.createdAtFrom
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
                .toString(),
            }
          : delete variables.createdAtFrom),
        ...(variables.createdAtTo
          ? {
              createdAtTo: variables.createdAtTo
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
                .toString(),
            }
          : delete variables.createdAtTo),
      },
    });
  },
  /**
   * @returns {Array|Boolean}
   */
  getFilters() {
    return connection.graph({
      query: print(logHistoryFilter),
    });
  },
  /**
   * @returns {Array|Boolean}
   */
  getItem(id) {
    return connection.graph({
      query: print(logHistory),
      variables: { id: "/api/fapi/log_histories/" + id },
    });
  },
};
