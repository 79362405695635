import { repositoryFactory } from "@/core/api/repositoryFactory";
import {
  CREATE_MDR_COLLECTION,
  READ_MDR_COLLECTION,
  RESET_MDR,
  UPDATE_MDR_MASTERCARD_COLLECTION,
  UPDATE_MDR_MASTERCARD_COUNTER,
  UPDATE_MDR_UNIONPAY_COLLECTION,
  UPDATE_MDR_UNIONPAY_COUNTER,
  UPDATE_MDR_VISA_COLLECTION,
  UPDATE_MDR_VISA_COUNTER,
} from "./mutation";

const mdrRepository = repositoryFactory.get("mdr");
const invoiceGeneratorRepository = repositoryFactory.get("invoiceGenerator");
const cardProductTypeRepository = repositoryFactory.get("cardProductType");
export default {
  namespaced: true,
  state: {
    visa: [],
    visaCounter: [],
    mastercard: [],
    mastercardCounter: [],
    unionpay: [],
    unionpayCounter: [],
  },
  mutations: {
    [READ_MDR_COLLECTION](state, data) {
      state.visa = data.visa;
      state.visaCounter = data.visaCounter;
      state.mastercard = data.mastercard;
      state.mastercardCounter = data.mastercardCounter;
      state.unionpay = data.unionpay;
      state.unionpayCounter = data.unionpayCounter;
    },
    [CREATE_MDR_COLLECTION](state, data) {
      state = data;
    },
    [CREATE_MDR_COLLECTION]() {},
    [UPDATE_MDR_VISA_COLLECTION](state, data) {
      state.visa = data;
    },
    [UPDATE_MDR_VISA_COUNTER](state, data) {
      state.visaCounter = data;
    },
    [UPDATE_MDR_MASTERCARD_COLLECTION](state, data) {
      state.mastercard = data;
    },
    [UPDATE_MDR_MASTERCARD_COUNTER](state, data) {
      state.mastercardCounter = data;
    },
    [UPDATE_MDR_UNIONPAY_COLLECTION](state, data) {
      state.unionpay = data;
    },
    [UPDATE_MDR_UNIONPAY_COUNTER](state, data) {
      state.unionpayCounter = data;
    },
    [RESET_MDR](state) {
      state.visa = [];
      state.visaCounter = [];
      state.mastercard = [];
      state.mastercardCounter = [];
      state.unionpay = [];
      state.unionpayCounter = [];
    },
  },
  getters: {
    getCollection: (state) => {
      return state;
    },
    getVisa: (state) => {
      return state.visa;
    },
    getVisaCounter: (state) => {
      return state.visaCounter;
    },
    getVisaCounterLength: (state) => {
      return state.visaCounter.length;
    },
    getMastercard: (state) => {
      return state.mastercard;
    },
    getMastercardCounter: (state) => {
      return state.mastercardCounter;
    },
    getMastercardCounterLength: (state) => {
      return state.mastercardCounter.length;
    },
    getUnionpay: (state) => {
      return state.unionpay;
    },
    getUnionpayCounter: (state) => {
      return state.unionpayCounter;
    },
    getUnionpayCounterLength: (state) => {
      return state.unionpayCounter.length;
    },
  },
  actions: {
    async getCardProductsList() {
      const list = await cardProductTypeRepository.getList();
      let collection = [];
      list.cardProductTypes.edges.forEach((nodes) => {
        collection.push({ id: nodes.node._id, name: nodes.node.name });
      });
      return collection;
    },
    async readCollection({ commit }, invoiceGeneratorId) {
      const collection = await mdrRepository.readCollection(invoiceGeneratorId);
      let visa = [];
      let visaCounter = [];
      let visaCount = 0;
      let mastercard = [];
      let mastercardCounter = [];
      let mastercardCount = 0;
      let unionpay = [];
      let unionpayCounter = [];
      let unionpayCount = 0;

      collection.mdrs.edges.forEach((nodes) => {
        if (nodes.node.cardScheme === 1) {
          mastercard.push({
            amountMin: (nodes.node.amountMin / 100).toFixed(2),
            amountPercent: (nodes.node.amountPercent / 100).toFixed(2),
            influence: nodes.node.influence,
            cardScheme: nodes.node.cardScheme,
            cardProductType: nodes.node.cardProductType,
            region: nodes.node.region,
          });
          mastercardCounter.push(mastercardCount++);
        } else if (nodes.node.cardScheme === 2) {
          visa.push({
            amountMin: (nodes.node.amountMin / 100).toFixed(2),
            amountPercent: (nodes.node.amountPercent / 100).toFixed(2),
            influence: nodes.node.influence,
            cardScheme: nodes.node.cardScheme,
            cardProductType: nodes.node.cardProductType,
            region: nodes.node.region,
          });
          visaCounter.push(visaCount++);
        } else if (nodes.node.cardScheme === 3) {
          unionpay.push({
            amountMin: (nodes.node.amountMin / 100).toFixed(2),
            amountPercent: (nodes.node.amountPercent / 100).toFixed(2),
            influence: nodes.node.influence,
            cardScheme: nodes.node.cardScheme,
            cardProductType: nodes.node.cardProductType,
            region: nodes.node.region,
          });
          unionpayCounter.push(unionpayCount++);
        }
      });

      commit(READ_MDR_COLLECTION, {
        visa,
        visaCounter,
        mastercard,
        mastercardCounter,
        unionpay,
        unionpayCounter,
      });
    },
    async createCollection({ commit, state }, { invoiceGeneratorIriId }) {
      await invoiceGeneratorRepository.update({
        id: invoiceGeneratorIriId,
        mdrs: [],
      });
      for (const mastercard of state.mastercard) {
        if (mastercard) {
          mdrRepository.createCollection({
            invoiceGenerator: invoiceGeneratorIriId,
            amountMin: parseInt(mastercard.amountMin || 0),
            amountPercent: parseInt(mastercard.amountPercent || 0),
            transactionType: mastercard.transactionType,
            influence: mastercard.influence,
            cardProductType: mastercard.cardProductType,
            region: mastercard.region,
            cardScheme: 1,
          });
        }
      }

      for (const visa of state.visa) {
        if (visa) {
          mdrRepository.createCollection({
            invoiceGenerator: invoiceGeneratorIriId,
            amountMin: parseInt(visa.amountMin || 0),
            amountPercent: parseInt(visa.amountPercent || 0),
            transactionType: visa.transactionType,
            influence: visa.influence,
            cardProductType: visa.cardProductType,
            region: visa.region,
            cardScheme: 2,
          });
        }
      }

      for (const unionpay of state.unionpay) {
        if (unionpay) {
          mdrRepository.createCollection({
            invoiceGenerator: invoiceGeneratorIriId,
            amountMin: parseInt(unionpay.amountMin || 0),
            amountPercent: parseInt(unionpay.amountPercent || 0),
            transactionType: unionpay.transactionType,
            influence: unionpay.influence,
            cardProductType: unionpay.cardProductType,
            region: unionpay.region,
            cardScheme: 3,
          });
        }
      }

      commit(RESET_MDR);
    },
    async updateVisa({ commit }, data) {
      commit(UPDATE_MDR_VISA_COLLECTION, data);
    },
    async updateVisaCounter({ commit }, data) {
      commit(UPDATE_MDR_VISA_COUNTER, data);
    },
    async updateMastercard({ commit }, data) {
      commit(UPDATE_MDR_MASTERCARD_COLLECTION, data);
    },
    async updateMastercardCounter({ commit }, data) {
      commit(UPDATE_MDR_MASTERCARD_COUNTER, data);
    },
    async updateUnionpay({ commit }, data) {
      commit(UPDATE_MDR_UNIONPAY_COLLECTION, data);
    },
    async updateUnionpayCounter({ commit }, data) {
      commit(UPDATE_MDR_UNIONPAY_COUNTER, data);
    },
    async reset({ commit }) {
      commit(RESET_MDR);
    },
  },
};
