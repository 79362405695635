import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);

const context = require.context("../modules", true, /Routes\/.*(js)$/i);
const routes = [];

context.keys().forEach(function (key) {
  routes.push(...context(key).default);
});

const router = new VueRouter({
  routes: routes,
  base: "/",
  mode: "history",
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.name === "auto_login") {
    next();
  } else {
    if (to.name !== "login" && token === null) {
      next("/login");
    } else {
      next();
    }
  }
});

router.onError(function (e) {
  console.error(e);
});

Vue.router = router;

export default router;
