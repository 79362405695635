export default [
  {
    path: "/auto_login",
    name: "auto_login",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/AutoLogin"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      layout: "Login",
    },
    component: () => import("../Pages/Login"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      layout: "Login",
    },
    component: () => import("../Pages/Logout"),
  },
];
