<template>
  <div class="container">
    <div class="main"><router-view></router-view></div>

    <div class="footer">
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} Tribe Payments<br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
};
</script>
