import connection from "../connection";

const customUrl = process.env.VUE_APP_API_URL;

export default {
  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getBankAccounts() {
    return connection.get(customUrl + "/bank/get-accounts");
  },
};
