import connection from "../connection";
import { print } from "graphql";
import transactions from "../graphql/queries/transactions.graphql";

export default {
  /**
   * @returns {Array|Boolean}
   */
  readCollection(pageSize, page, invoice_id) {
    let variables = {
      page: btoa((page - 1) * pageSize - 1),
      pageSize: pageSize,
      invoice_id: invoice_id,
    };
    if (!page || 0 == page) {
      delete variables.page;
      delete variables.pageSize;
    }

    return connection.graph({
      query: print(transactions),
      variables: variables,
    });
  },
};
