import Vue from "vue";
import store from "./core/store";
import router from "./core/router";
import App from "./App.vue";
import "@/assets/fonts/WeblySleekUI/stylesheet.css";
import { CanDirective } from "./core/directives/can";
import antInputDirective from "ant-design-vue/es/_util/antInputDirective";
import * as Resize from "iframe-resizer/js/iframeResizer.contentWindow";
import moment from "moment-timezone";

Vue.use(antInputDirective);
Vue.use(Resize);
Vue.directive("can", CanDirective);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.performance = process.env.NODE_ENV !== "production";
moment.tz.setDefault("Europe/London");

new Vue({
  router,
  store,
  created: function () {
    store.commit("userStoreModule/AUTH_RELOAD");
  },
  render: (h) => h(App),
}).$mount("#app");
