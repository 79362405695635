import connection from "../connection";
import { print } from "graphql";

import invoiceGenerators from "../graphql/queries/invoiceGenerators.graphql";
import invoiceGeneratorFilters from "../graphql/queries/invoiceGeneratorFilters.graphql";
import invoiceGenerator from "../graphql/queries/invoiceGenerator.graphql";
import createInvoiceGenerator from "../graphql/mutations/createInvoiceGenerator.graphql";
import updateInvoiceGenerator from "../graphql/mutations/updateInvoiceGenerator.graphql";
import deleteInvoiceGenerator from "../graphql/mutations/deleteInvoiceGenerator.graphql";
import invoiceGeneratorFormValues from "../graphql/queries/invoiceGeneratorFormValues.graphql";

const customUrl = process.env.VUE_APP_API_URL;

export default {
  /**
   * @returns {Array|Boolean}
   */
  readCollection(filters) {
    if (!filters.page || filters.page == 0) {
      delete filters.page;
    }

    /** Convert arrays to int */
    const convert = [
      "terminals_id_list",
      "accounts_id_list",
      "company_id_list",
      "payerCompany_id_list",
      "processingAccounts_id_list",
      "invoicePeriod_list",
    ];
    for (const key of convert) {
      if (filters[key] !== undefined) {
        filters[key] = filters[key].toString().split(",").map(Number);
      }
    }

    return connection.graph({
      query: print(invoiceGenerators),
      variables: {
        ...filters,
        ...(filters.page
          ? {
              page: btoa((filters.page - 1) * filters.pageSize - 1),
              first: filters.pageSize,
            }
          : {}),
      },
    });
  },
  getFilterValues() {
    return connection.graph({
      query: print(invoiceGeneratorFilters),
    });
  },
  /**
   * @returns {Array|Boolean}
   */
  readFormCollections() {
    return connection.graph(
      {
        query: print(invoiceGeneratorFormValues),
      },
      true
    );
  },
  /**
   * @param id
   * @returns {Array|Boolean}
   */
  readItem(id) {
    return connection.graph({
      query: print(invoiceGenerator),
      variables: { id },
    });
  },
  /**
   * @param invoiceGenerator
   * @returns {Array|Boolean}
   */
  create(invoiceGenerator) {
    let data = this.convertDataValues(invoiceGenerator);

    return connection.graph({
      query: print(createInvoiceGenerator),
      variables: {
        input: { ...data },
      },
    });
  },
  convertDataValues(data) {
    if (data.terminals !== undefined) {
      data.terminals = data.terminals.toString().split(",");
    }
    if (data.payerCompany !== undefined) {
      data.payerCompany = data.payerCompany.toString();
    }
    if (data.company !== undefined) {
      data.company = data.company.toString();
    }
    if (data.beneficiaryCompany !== undefined) {
      data.beneficiaryCompany = data.beneficiaryCompany.toString();
    }
    if (data.pdfProcessingTemplate !== undefined) {
      data.pdfProcessingTemplate = data.pdfProcessingTemplate.toString();
    }
    if (data.accounts !== undefined) {
      data.accounts = data.accounts.toString().split(",");
    }
    if (data.processingAccounts !== undefined) {
      data.processingAccounts = data.processingAccounts.toString().split(",");
    }
    if (data.title !== undefined) {
      data.title = data.title.toString();
    }
    if (data.generatorEnabledFrom !== undefined) {
      data.generatorEnabledFrom = parseInt(data.generatorEnabledFrom);
    }
    if (data.generatorEnabledTo !== undefined) {
      data.generatorEnabledTo = parseInt(data.generatorEnabledTo);
    }

    if (data.rollingEnabled) {
      data.rollingEnabled = 1;
    }

    if (data.zeroInvoiceEnabled) {
      data.zeroInvoiceEnabled = 1;
    }

    if (data.emailEnabled) {
      data.emailEnabled = 1;
    }

    if (data.emailTemplate !== undefined) {
      data.emailTemplate = data.emailTemplate.toString();
    }

    return data;
  },
  /**
   * @param invoiceGenerator
   * @returns {Array|Boolean}
   */
  update(invoiceGenerator) {
    let data = this.convertDataValues(invoiceGenerator);
    return connection.graph({
      query: print(updateInvoiceGenerator),
      variables: { input: { ...data } },
    });
  },
  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  changeStateToSave(id) {
    return connection.get(
      customUrl + "/invoice_generator/" + id + "/transition/save"
    );
  },
  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAvailableTransitions(id) {
    return connection.get(
      customUrl + "/invoice_generator/" + id + "/available_transitions"
    );
  },
  /**
   * @param id
   * @returns {Array|Boolean}
   */
  delete(id) {
    return connection.graph({
      query: print(deleteInvoiceGenerator),
      variables: {
        input: { id },
      },
    });
  },
  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  copy(id) {
    return connection.get(customUrl + "/invoice_generator/" + id + "/copy");
  },
};
