<template>
  <layout :is="layout">
    <router-view />
  </layout>
</template>

<script>
import PlainLayout from "./core/layouts/Plain";
import DefaultLayout from "./core/layouts/Default";
import LoginLayout from "./core/layouts/Login";
import PreloadLayout from "./core/layouts/Preload";

export default {
  components: {
    PreloadLayout: PreloadLayout,
    LoginLayout: LoginLayout,
    DefaultLayout: DefaultLayout,
    PlainLayout: PlainLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "Preload") + "Layout";
    },
  },
};
</script>
