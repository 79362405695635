import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_RELOAD,
  AUTH_PERMISSIONS,
  USER_INFO,
} from "./mutation";
import { repositoryFactory } from "@/core/api/repositoryFactory";

const userRepository = repositoryFactory.get("user");
const authRepository = repositoryFactory.get("auth");
export default {
  namespaced: true,
  state: {
    token: false,
    userInfo: false,
    username: "",
    permissions: [],
  },
  getters: {
    isLogged() {
      return null !== localStorage.getItem("token");
    },
    getToken: (state) => {
      return state.token;
    },
    canAccess: (state) => (permission) => {
      return state.permissions.indexOf(permission) > -1; // exists in list
    },
    getUserInfo: (state) => {
      return state.userInfo;
    },
  },
  mutations: {
    [AUTH_LOGIN](state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    [AUTH_PERMISSIONS](state, permissions) {
      state.permissions = [];
      if (permissions) {
        for (let i = 0, len = permissions.length; i < len; i++) {
          state.permissions.push(permissions[i]);
        }
      }
      localStorage.setItem("permissions", JSON.stringify(state.permissions));
    },
    [AUTH_LOGOUT](state) {
      state.token = false;
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("userInfo");
    },
    [AUTH_RELOAD](state) {
      if (!state.permissions || state.permissions.length < 1) {
        if (localStorage.getItem("permissions")) {
          state.permissions = JSON.parse(localStorage.getItem("permissions"));
        }
      }
      if (!state.token) {
        if (localStorage.getItem("token")) {
          state.token = localStorage.getItem("token");
        }
      }
      if (!state.userInfo) {
        if (localStorage.getItem("userInfo")) {
          state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        }
      }
    },
    [USER_INFO](state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
  actions: {
    /**
     * @param commit
     * @param string token
     * @param int userId
     * @returns {Promise<boolean>}
     */
    async autoLogin({ commit }, { token, userId }) {
      commit(AUTH_LOGOUT);
      commit(AUTH_LOGIN, atob(token));
      const permissionData = await userRepository.getPermissions(userId);
      const permissionEdges = permissionData.user.role.permissions.edges;
      let permissions = [];
      for (let i = 0, len = permissionEdges.length; i < len; i++) {
        permissions.push(permissionEdges[i].node.name);
      }
      commit(USER_INFO, permissionData);
      commit(AUTH_PERMISSIONS, permissions);
    },
    /**
     * @param commit
     * @param string username
     * @param string password
     * @returns {Promise<boolean>}
     */
    async login({ commit }, { username, password }) {
      const response = await authRepository.login(username, password);
      const data = response.data;
      commit(AUTH_LOGIN, data.token);
      commit(AUTH_PERMISSIONS, data.data.permissions);

      const permissionData = await userRepository.getPermissions(
        data.data.userId
      );
      commit(USER_INFO, permissionData);
    },
    logout({ commit }) {
      commit(AUTH_LOGOUT);
    },
  },
};
