export default [
  {
    name: "Custom Fee Setup",
    path: "/invoice_generator/custom_fee_setup",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Form"),
  },
];
