export default [
  {
    name: "Transaction",
    path: "/transaction",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
  {
    name: "Invoice Transactions",
    path: "/transaction/invoice/:invoiceId",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
  {
    name: "View Transaction",
    path: "/invoice/view",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
];
