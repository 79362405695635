import connection from "../connection";
import gql from "graphql-tag";
import { print } from "graphql";

const transactionTypeQueryList = gql`
  query getTransactionType {
    expense: transactionTypes(order: { ordering: "asc" }, direction: 1) {
      edges {
        node {
          _id
          id
          title
          code
        }
      }
      totalCount
    }
    income: transactionTypes(order: { ordering: "asc" }, direction: 2) {
      edges {
        node {
          _id
          id
          title
          code
        }
      }
      totalCount
    }
  }
`;

export default {
  /**
   * @returns {Array|Boolean}
   */
  getCollection() {
    return connection.graph({
      query: print(transactionTypeQueryList),
    });
  },
};
