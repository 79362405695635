import connection from "../connection";
import gql from "graphql-tag";
import { print } from "graphql";

const currencyQueryList = gql`
  query getCurrencies($accountIds: [Int!]) {
    currencies(processingAccount_id_list: $accountIds) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        cursor
        node {
          code
        }
      }
    }
  }
`;

const allCurrenciesList = gql`
  query getCurrencies($pageSize: Int) {
    currencies(first: $pageSize) {
      edges {
        node {
          code
        }
      }
    }
  }
`;

export default {
  /**
   * @param accountIds
   * @returns {Array|Boolean}
   */
  get(accountIds) {
    return connection.graph({
      query: print(currencyQueryList),
      variables: { accountIds },
    });
  },
  getAll() {
    return connection.graph({
      query: print(allCurrenciesList),
      variables: { pageSize: 1000 },
    });
  },
};
