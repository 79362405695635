import connection from "../connection";

const customUrl = process.env.VUE_APP_API_URL;

export default {
  getLogoFile(id) {
    return connection.get(customUrl + "/invoice_generator_logo_file/" + id, {
      responseType: "blob",
    });
  },
};
