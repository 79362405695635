export default [
  {
    name: "Merchant Income Expense",
    path: "/invoice_generator/merchant_income_expense",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Form"),
  },
];
