import processingAccountRepository from "./repos/processingAccount";
import authRepository from "./repos/auth";
import userRepository from "./repos/user";
import companyRepository from "./repos/company";
import currencyRepository from "./repos/currency";
import invoiceRepository from "./repos/invoice";
import invoiceGeneratorRepository from "./repos/invoiceGenerator";
import invoiceGeneratorLogoRepository from "./repos/invoiceGeneratorLogo";
import terminalRepository from "./repos/terminal";
import transactionRepository from "./repos/transaction";
import transactionTypeRepository from "./repos/transactionType";
import feeSetupRepository from "./repos/feeSetup";
import incomeExpenseSetupRepository from "./repos/incomeExpenseSetup";
import mdrRepository from "./repos/mdr";
import customFeeRepository from "./repos/customFee";
import feeUseTypeRepository from "./repos/feeUseType";
import downloadRepository from "./repos/download";
import paymentRepository from "./repos/payment";
import cardProductTypeRepository from "./repos/cardProductType";
import logHistoryRepository from "./repos/logHistory";
import bankAccountRepository from "./repos/bankAccount";

const repositories = {
  processingAccount: processingAccountRepository,
  auth: authRepository,
  user: userRepository,
  company: companyRepository,
  currency: currencyRepository,
  invoice: invoiceRepository,
  invoiceGenerator: invoiceGeneratorRepository,
  invoiceGeneratorLogo: invoiceGeneratorLogoRepository,
  terminal: terminalRepository,
  transaction: transactionRepository,
  transactionType: transactionTypeRepository,
  feeSetup: feeSetupRepository,
  incomeExpenseSetup: incomeExpenseSetupRepository,
  mdr: mdrRepository,
  customFee: customFeeRepository,
  feeUseType: feeUseTypeRepository,
  download: downloadRepository,
  payment: paymentRepository,
  cardProductType: cardProductTypeRepository,
  logHistory: logHistoryRepository,
  bankAccount: bankAccountRepository,
};

/**
 * @type {{data: (function(*): AxiosInstance), get: (function(*): *)}}
 */
export const repositoryFactory = {
  get: (name) => repositories[name],
  data: (result) => result.data.data,
};
