import {
  CREATE_FEE_SETUP_COLLECTION,
  READ_FEE_SETUP_COLLECTION,
  RESET_SETUP_FEE,
  UPDATE_FEE_SETUP_FEE_COLLECTION,
  UPDATE_FEE_SETUP_FEE_COUNTER,
} from "./mutation";

import { repositoryFactory } from "@/core/api/repositoryFactory";

const invoiceGeneratorRepository = repositoryFactory.get("invoiceGenerator");
const customFeeRepository = repositoryFactory.get("customFee");
const feeUseTypeRepository = repositoryFactory.get("feeUseType");

export default {
  namespaced: true,
  state: {
    customFee: [],
    customFeeCounter: [],
    customFeeUseTypes: [],
  },
  mutations: {
    [READ_FEE_SETUP_COLLECTION](state, data) {
      state.customFee = data.customFee;
      state.customFeeCounter = data.customFeeCounter;
      state.customFeeUseTypes = data.customFeeUseTypes;
    },
    [CREATE_FEE_SETUP_COLLECTION]() {},
    [UPDATE_FEE_SETUP_FEE_COLLECTION](state, data) {
      state.customFee = data;
    },
    [UPDATE_FEE_SETUP_FEE_COUNTER](state, data) {
      state.customFeeCounter = data;
    },
    [RESET_SETUP_FEE](state) {
      state.customFee = [];
      state.customFeeCounter = [];
      state.customFeeUseTypes = [];
    },
  },
  getters: {
    getCollection: (state) => {
      return state;
    },
    getCustomFee: (state) => {
      return state.customFee;
    },
    getCustomFeeCounter: (state) => {
      return state.customFeeCounter;
    },
    getCustomFeeCounterLength: (state) => {
      return state.customFeeCounter.length;
    },
    getCustomFeeUseTypes: (state) => {
      return state.customFeeUseTypes;
    },
  },
  actions: {
    async readCollection({ commit }, invoiceGeneratorId) {
      let customFee = [];
      let customFeeCounter = [];
      let customFeeUseTypes = [];

      commit(RESET_SETUP_FEE);

      const collection = await customFeeRepository.readCollection(
        invoiceGeneratorId
      );

      let i = 0;
      collection.customFees.edges.forEach((nodes) => {
        customFee.push({
          id: nodes.node.id,
          _id: nodes.node._id,
          feeUseType: nodes.node.feeUseType._id,
          title: nodes.node.title,
          fee: nodes.node.fee,
        });
        customFeeCounter.push(i++);
      });

      let feeTypes = await feeUseTypeRepository.readCollection();
      if (feeTypes.feeUseTypes) {
        customFeeUseTypes = feeTypes.feeUseTypes.edges;
      }

      commit(READ_FEE_SETUP_COLLECTION, {
        customFee,
        customFeeCounter,
        customFeeUseTypes,
      });
    },

    async createCollection({ commit, state }, { invoiceGeneratorIriId }) {
      commit(CREATE_FEE_SETUP_COLLECTION);

      await invoiceGeneratorRepository.update({
        id: invoiceGeneratorIriId,
        customFees: [],
      });

      for (const fee of state.customFee) {
        customFeeRepository.createCollection({
          invoiceGenerator: invoiceGeneratorIriId,
          feeUseType: fee.feeUseType,
          title: fee.title,
          fee: fee.fee,
        });
      }

      commit(RESET_SETUP_FEE);
    },
    async updateCustomFee({ commit }, data) {
      commit(UPDATE_FEE_SETUP_FEE_COLLECTION, data);
    },
    async updateCustomFeeCounter({ commit }, data) {
      commit(UPDATE_FEE_SETUP_FEE_COUNTER, data);
    },
    async reset({ commit }) {
      commit(RESET_SETUP_FEE);
    },
  },
};
