export const READ_MDR_COLLECTION = "READ_MDR_COLLECTION";
export const CREATE_MDR_COLLECTION = "CREATE_MDR_COLLECTION";

export const RESET_MDR = "RESET_MDR";

export const UPDATE_MDR_VISA_COLLECTION = "UPDATE_MDR_VISA_COLLECTION";
export const UPDATE_MDR_MASTERCARD_COLLECTION =
  "UPDATE_MDR_MASTERCARD_COLLECTION";
export const UPDATE_MDR_UNIONPAY_COLLECTION = "UPDATE_MDR_UNIONPAY_COLLECTION";

export const UPDATE_MDR_VISA_COUNTER = "UPDATE_MDR_VISA_COUNTER";
export const UPDATE_MDR_MASTERCARD_COUNTER = "UPDATE_MDR_MASTERCARD_COUNTER";
export const UPDATE_MDR_UNIONPAY_COUNTER = "UPDATE_MDR_UNIONPAY_COUNTER";
