export const READ_INVOICE_GENERATOR_COLLECTION =
  "READ_INVOICE_GENERATOR_COLLECTION";

export const READ_INVOICE_GENERATOR = "READ_INVOICE_GENERATOR";
export const CREATE_INVOICE_GENERATOR = "CREATE_INVOICE_GENERATOR";
export const UPDATE_INVOICE_GENERATOR = "UPDATE_INVOICE_GENERATOR";
export const RESET_INVOICE_GENERATOR = "RESET_INVOICE_GENERATOR";
export const INVOICE_GENERATOR_SET_FILTERS = "INVOICE_GENERATOR_SET_FILTERS";
export const INVOICE_GENERATOR_RESET_FILTERS =
  "INVOICE_GENERATOR_RESET_FILTERS";
export const EVENT_INVOICE_GENERATOR_FILTER_SUBMIT =
  "EVENT_INVOICE_GENERATOR_FILTER_SUBMIT";
export const INVOICE_GENERATOR_SET_PAGINATION =
  "INVOICE_GENERATOR_SET_PAGINATION";
