export default [
  {
    name: "Log Histories",
    path: "/log_history",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
  {
    name: "Log History",
    path: "/log_history/show/:id(\\d+)",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Show"),
  },
];
