import connection from "../connection";
import { print } from "graphql";
import moment from "moment";
import invoices from "../graphql/queries/invoices.graphql";
import invoice from "../graphql/queries/invoice.graphql";
import invoicesForSummary from "../graphql/queries/invoicesForSummary.graphql";
import invoiceFilter from "../graphql/queries/invoiceFilter.graphql";

const customUrl = process.env.VUE_APP_API_URL;

export default {
  /**
   * @returns {Array|Boolean}
   */
  readCollection(variables) {
    if (!variables.page || variables.page == 0) {
      delete variables.page;
    }
    return connection.graph({
      query: print(invoices),
      variables: this.getCollectionVariables(variables),
    });
  },
  /**
   * @returns {Array|Boolean}
   */
  readCollectionForSummary(variables) {
    if (!variables.page || variables.page == 0) {
      delete variables.page;
    }
    return connection.graph({
      query: print(invoicesForSummary),
      variables: this.getCollectionVariables(variables),
    });
  },
  /**
   * @returns {Array|Boolean}
   */
  getFilters() {
    return connection.graph({
      query: print(invoiceFilter),
    });
  },
  /**
   * @param id
   * @returns {Array|Boolean}
   */
  readItem(id) {
    return connection.graph({
      query: print(invoice),
      variables: { id: "/api/fapi/invoices/" + id },
    });
  },
  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  getPreview(id) {
    return connection.get(customUrl + "/invoice/preview/" + id);
  },
  /**
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  getManualInvoicePreview(data) {
    return connection.post(customUrl + "/invoice/preview-manual-invoice", data);
  },
  /**
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  generateManualInvoice(data) {
    return connection.post(
      customUrl + "/invoice/generate-manual-invoice",
      data
    );
  },
  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  getPreviewFull(id) {
    return connection.get(customUrl + "/invoice/preview_full/" + id);
  },
  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  getPreviewGenerated(id, type) {
    return connection.get(
      customUrl + "/invoice/preview_generated/" + type + "-" + id
    );
  },

  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  walletPay(ids) {
    return connection.post(customUrl + "/invoice/wallet-pay", {
      invoices: ids,
    });
  },

  /**
   * @param query
   * @returns {Promise<AxiosResponse<T>>}
   */
  searchForFilter(query) {
    return connection.post(customUrl + "/invoice/search", {
      query: query,
    });
  },

  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  deleteManualInvoice(id) {
    return connection.post(customUrl + "/invoice/delete-manual-invoice", {
      invoice: id,
    });
  },

  getCollectionVariables(variables) {
    let data = {
      ...variables,
      ...(variables.page
        ? {
            page: btoa((variables.page - 1) * variables.pageSize - 1),
            first: variables.pageSize,
          }
        : {}),
      ...(variables.generationDateFrom
        ? {
            generationDateFrom: variables.generationDateFrom
              .startOf("day")
              .unix()
              .toString(),
          }
        : { generationDateFrom: null }),
      ...(variables.generationDateTo
        ? {
            generationDateTo: variables.generationDateTo
              .endOf("day")
              .unix()
              .toString(),
          }
        : delete variables.generationDateTo),
      ...(variables.expectedPaymentDateFrom
        ? {
            expectedPaymentDateFrom: variables.expectedPaymentDateFrom
              .startOf("day")
              .unix()
              .toString(),
          }
        : { expectedPaymentDateFrom: null }),
      ...(variables.expectedPaymentDateTo
        ? {
            expectedPaymentDateTo: variables.expectedPaymentDateTo
              .endOf("day")
              .unix()
              .toString(),
          }
        : delete variables.expectedPaymentDateTo),
      ...(variables.dateBillingFrom
        ? {
            dateBillingFrom: moment(variables.dateBillingFrom)
              .utc()
              .startOf("day")
              .unix()
              .toString(),
          }
        : { dateBillingFrom: null }),
      ...(variables.dateBillingTo
        ? {
            dateBillingTo: moment(variables.dateBillingTo)
              .utc()
              .endOf("day")
              .unix()
              .toString(),
          }
        : delete variables.dateBillingTo),
      ...(variables.invoice_show_zero_invoice
        ? {}
        : {
            total: 0,
          }),
    };

    if (data.dateBillingFrom === null) {
      delete data.dateBillingFrom;
    }
    if (data.expectedPaymentDateFrom === null) {
      delete data.expectedPaymentDateFrom;
    }
    if (data.generationDateFrom === null) {
      delete data.generationDateFrom;
    }

    if (data["invoiceGenerator_company_id_list"] !== undefined) {
      data["invoiceGenerator_company_id_list"] = data[
        "invoiceGenerator_company_id_list"
      ].map((str) => {
        return Number(str);
      });
    }

    if (data["invoiceGenerator_invoicePeriod_list"] !== undefined) {
      data["invoiceGenerator_invoicePeriod_list"] = data[
        "invoiceGenerator_invoicePeriod_list"
      ].map((str) => {
        return Number(str);
      });
    }

    if (data["type_list"] !== undefined) {
      data["type_list"] = data["type_list"].map((str) => {
        return Number(str);
      });
    }

    if (data["id_list"] !== undefined) {
      data["id_list"] = data["id_list"].map((str) => {
        return Number(str);
      });
    }

    return data;
  },
  getTransactionWithSettlementsList(invoiceId) {
    return connection.post(
      customUrl + "/invoice/" + invoiceId + "/transaction-settlement-list",
      {}
    );
  },
};
