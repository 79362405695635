import connection from "../connection";
import { print } from "graphql";
import feeSetups from "../graphql/queries/invoiceGeneratorFees.graphql";
import createFeeSetup from "../graphql/mutations/createFeeSetup.graphql";
import deleteFeeSetup from "../graphql/mutations/deleteFeeSetup.graphql";

export default {
  /**
   * @param invoiceGenerator_id
   * @returns {Array|Boolean}
   */
  readCollection(invoiceGenerator_id) {
    return connection.graph({
      query: print(feeSetups),
      variables: { invoiceGenerator_id },
    });
  },
  /**
   * @param transactionType
   * @returns {Array|Boolean}
   */
  createCollection(transactionType) {
    if (transactionType.transactionType !== undefined) {
      transactionType.transactionType =
        transactionType.transactionType.toString();
    }

    return connection.graph({
      query: print(createFeeSetup),
      variables: { input: { ...transactionType } },
    });
  },
  /**
   * @param transactionTypeIriId
   * @returns {Array|Boolean}
   */
  deleteCollection(transactionTypeIriId) {
    return connection.graph({
      query: print(deleteFeeSetup),
      variables: { input: { id: transactionTypeIriId } },
    });
  },
};
