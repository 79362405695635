export default [
  {
    name: "Invoice Generators",
    path: "/invoice_generator",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
  {
    name: "Create Invoice Generator",
    path: "/invoice_generator/new",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Form"),
  },
  {
    name: "InvoiceGeneratorUpdate",
    path: "/invoice_generator/edit/:id(\\w+)",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Form"),
  },
  {
    name: "Invoice Generators Fallback",
    path: "/invoice_generator/edit/*",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
];
