import {
  CREATE_INVOICE_GENERATOR,
  RESET_INVOICE_GENERATOR,
  READ_INVOICE_GENERATOR,
  READ_INVOICE_GENERATOR_COLLECTION,
  UPDATE_INVOICE_GENERATOR,
  INVOICE_GENERATOR_SET_FILTERS,
  EVENT_INVOICE_GENERATOR_FILTER_SUBMIT,
  INVOICE_GENERATOR_RESET_FILTERS,
  INVOICE_GENERATOR_SET_PAGINATION,
} from "./mutation";

import { repositoryFactory } from "@/core/api/repositoryFactory";
import { structure } from "../Mixins/formItemNames";
import { flattenGraph } from "../../../core/utils/array";
import moment from "moment";

const invoiceGeneratorRepository = repositoryFactory.get("invoiceGenerator");

export default {
  namespaced: true,
  state: {
    collection: [],
    item: {},
    id: null,
    _id: null,
    filters: {
      isVisible: 1,
    },
    paginator: {
      page: 1,
      pageSize: parseInt(process.env.VUE_APP_LIST_DEFAULT_SIZE),
    },
  },
  mutations: {
    [READ_INVOICE_GENERATOR_COLLECTION](state, data) {
      state.collection = data;
    },
    [READ_INVOICE_GENERATOR](state, data) {
      const { id, _id, ...invoiceGenerator } = data;
      state.id = id;
      state._id = _id;
      state.item = invoiceGenerator;
    },
    [INVOICE_GENERATOR_SET_PAGINATION](state, data) {
      state.paginator = { ...state.paginator, ...data };
    },
    [CREATE_INVOICE_GENERATOR](state, data) {
      const { id, _id, ...invoiceGenerator } = data;
      state.id = id;
      state._id = _id;
      state.item = invoiceGenerator;
    },
    [UPDATE_INVOICE_GENERATOR](state, data) {
      const { id, _id, ...invoiceGenerator } = data;
      state.id = id;
      state._id = _id;
      state.item = invoiceGenerator;
    },
    [RESET_INVOICE_GENERATOR](state) {
      state.item = {};
      state.id = null;
      state._id = null;
    },
    [INVOICE_GENERATOR_SET_FILTERS](state, data) {
      state.filters = {
        ...state.filters,
        ...data,
      };
    },
    [INVOICE_GENERATOR_RESET_FILTERS](state) {
      state.filters = {};
    },
    [EVENT_INVOICE_GENERATOR_FILTER_SUBMIT]() {},
  },
  getters: {
    getCollection: (state) => {
      return state.collection;
    },
    getItem: (state) => {
      return state.item;
    },
    getId: (state) => {
      return state._id;
    },
    getIriId: (state) => {
      return state.id;
    },
    getFilters: (state) => {
      return {
        ...state.filters,
        ...state.paginator,
      };
    },
  },
  actions: {
    async readCollection({ commit }) {
      const collection = await invoiceGeneratorRepository.readCollection();
      commit(READ_INVOICE_GENERATOR_COLLECTION, collection);
    },
    async readItem({ commit }, id) {
      const item = await invoiceGeneratorRepository.readItem(id);

      if (item.invoiceGenerator.generatorEnabledFrom > 0) {
        item.invoiceGenerator.generatorEnabledFrom = moment(
          item.invoiceGenerator.generatorEnabledFrom,
          "X"
        );
      }
      if (item.invoiceGenerator.generatorEnabledTo > 0) {
        item.invoiceGenerator.generatorEnabledTo = moment(
          item.invoiceGenerator.generatorEnabledTo,
          "X"
        );
      }
      if (item.invoiceGenerator.generatorTime) {
        item.invoiceGenerator.generatorTime = moment(
          item.invoiceGenerator.generatorTime
        ).utcOffset(0);
      }

      commit(
        READ_INVOICE_GENERATOR,
        flattenGraph(structure(), item.invoiceGenerator)
      );
    },
    async createItem({ commit }, item) {
      item = await invoiceGeneratorRepository.create(item);
      commit(
        CREATE_INVOICE_GENERATOR,
        flattenGraph(structure(), item.createInvoiceGenerator.invoiceGenerator)
      );
    },
    async updateItem({ commit }, item) {
      item = await invoiceGeneratorRepository.update(item);
      commit(
        UPDATE_INVOICE_GENERATOR,
        flattenGraph(structure(), item.updateInvoiceGenerator.invoiceGenerator)
      );
    },
    async deleteItem({ commit }, id) {
      await invoiceGeneratorRepository.delete(id);
      commit(RESET_INVOICE_GENERATOR);
    },
    async reset({ commit }) {
      commit(RESET_INVOICE_GENERATOR);
    },
    async setFilters({ commit }, { ...params }) {
      commit(INVOICE_GENERATOR_SET_FILTERS, params);
    },
    async resetFilters({ commit }) {
      commit(INVOICE_GENERATOR_RESET_FILTERS);
    },
    async submitFilterForm({ commit }, { ...params }) {
      commit(EVENT_INVOICE_GENERATOR_FILTER_SUBMIT, params);
    },
    async setPagination({ commit }, { ...params }) {
      commit(INVOICE_GENERATOR_SET_PAGINATION, params);
    },
  },
};
