import connection from "../connection";

const resourceLogin = process.env.VUE_APP_API_URL + "/auth_token";
const resourceLogout = process.env.VUE_APP_API_URL + "/logout";

export default {
  /**
   * @param {string} username
   * @param {string} password
   */
  login(username, password) {
    return connection.post(resourceLogin, {
      username: username,
      password: password,
    });
  },
  /**
   * @param {string} username
   * @param {string} password
   */
  logout(username, password) {
    return connection.post(resourceLogout, {
      username: username,
      password: password,
    });
  },
};
