import {
  READ_INVOICE_COLLECTION,
  INVOICE_SET_PAGINATION,
  INVOICE_SET_FILTERS,
  INVOICE_RESET_FILTERS,
  INVOICE_SET_SORTING,
  EVENT_INVOICE_FILTER_SUBMIT,
  INVOICE_SET_PAY_IDS,
} from "./mutation";

export default {
  namespaced: true,
  state: {
    collection: [],
    item: {
      invoice: {},
      tainted: false,
      id: null,
    },
    paginator: {
      page: 1,
      pageSize: parseInt(process.env.VUE_APP_LIST_DEFAULT_SIZE),
    },
    selectedInvoices: [],
    sorting: {},
  },
  mutations: {
    [READ_INVOICE_COLLECTION](state, data) {
      state.collection = data;
    },
    [INVOICE_SET_PAGINATION](state, data) {
      state.paginator = { ...state.paginator, ...data };
    },
    [INVOICE_SET_PAY_IDS](state, data) {
      state.selectedInvoices = { ...state.selectedInvoices, ...data };
    },
    [INVOICE_SET_FILTERS](state, data) {
      state.filters = {
        ...state.filters,
        ...data,
      };
    },
    [INVOICE_RESET_FILTERS](state) {
      state.filters = { page: 0 };
      state.paginator = { page: 0 };
    },
    [INVOICE_SET_SORTING](state, data) {
      state.sorting = { ...state.sorting, ...data };
    },
    [EVENT_INVOICE_FILTER_SUBMIT]() {},
  },
  getters: {
    getAll: (state) => {
      return {
        ...state.paginator,
        ...state.filters,
        ...state.sorting,
      };
    },
    getSelectedInvoices: (state) => {
      return state.selectedInvoices;
    },
  },
  actions: {
    async setSelectedInvoices({ commit }, { ...params }) {
      commit(INVOICE_SET_PAY_IDS, params);
    },
    async setPagination({ commit }, { ...params }) {
      commit(INVOICE_SET_PAGINATION, params);
    },
    async setFilters({ commit }, { ...params }) {
      commit(INVOICE_SET_FILTERS, params);
    },
    async resetFilters({ commit }) {
      commit(INVOICE_RESET_FILTERS);
    },
    async setSorting({ commit }, { ...params }) {
      commit(INVOICE_SET_SORTING, params);
    },
    async submitFilterForm({ commit }, { ...params }) {
      commit(EVENT_INVOICE_FILTER_SUBMIT, params);
    },
  },
};
