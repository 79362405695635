<template>
  <div class="container">
    <div class="top">
      <div class="title"><a-icon type="file-text" /> Gateway Login</div>
    </div>

    <div class="main"><router-view></router-view></div>

    <div class="footer">
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} Tribe Payments<br />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "ant-design-vue";

export default {
  name: "LoginLayout",
  components: {
    "a-icon": Icon,
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  width: 100%;
  min-height: 100%;
  background-size: 100%;
  padding: 110px 0 144px;
  position: relative;
  .top {
    text-align: center;
    .title {
      font-size: 36px;
      font-weight: bold;
      line-height: 180px;
      color: #333;
    }
  }
  .main {
    min-width: 260px;
    width: 368px;
    margin: 0 auto;
  }
  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0 16px;
    margin: 48px 0 24px;
    text-align: center;
    img {
      height: 40px;
    }
  }
}
</style>
