import connection from "../connection";
import gql from "graphql-tag";
import { print } from "graphql";

const userInfo = gql`
  query getPermissions($userId: ID!) {
    user(id: $userId) {
      username
      company {
        pricingModel
      }
      role {
        name
        permissions {
          edges {
            node {
              name
            }
          }
        }
      }
    }
  }
`;

export default {
  /**
   * @param userId
   * @returns {Array|Boolean}
   */
  getPermissions(userId) {
    return connection.graph({
      query: print(userInfo),
      variables: { userId: "/api/users/" + userId },
    });
  },
};
