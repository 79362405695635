import connection from "../connection";
import { print } from "graphql";
import terminals from "../graphql/queries/terminals.graphql";

export default {
  /**
   * @param isEnabled
   * @param currency_code
   * @param processingAccount_id_list
   * @returns {Array|Boolean}
   */
  getCollection(isEnabled, currency_code, processingAccount_id_list = []) {
    return connection.graph({
      query: print(terminals),
      variables: { processingAccount_id_list, currency_code, isEnabled },
    });
  },
};
