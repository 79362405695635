import connection from "../connection";
import { print } from "graphql";
import processingAccounts from "../graphql/queries/processingAccounts.graphql";

export default {
  /**
   * @param company_id
   * @returns {Array|Boolean}
   */
  getCollection(company_id) {
    return connection.graph({
      query: print(processingAccounts),
      variables: { company_id, isActive: true, first: 1000 },
    });
  },
};
