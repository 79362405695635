import {
  CREATE_FEE_SETUP_COLLECTION,
  READ_FEE_SETUP_COLLECTION,
  RESET_SETUP_FEE,
  UPDATE_FEE_SETUP_CREDIT_COLLECTION,
  UPDATE_FEE_SETUP_CREDIT_COUNTER,
  UPDATE_FEE_SETUP_DEBIT_COLLECTION,
  UPDATE_FEE_SETUP_DEBIT_COUNTER,
} from "./mutation";

import { repositoryFactory } from "@/core/api/repositoryFactory";

const invoiceGeneratorRepository = repositoryFactory.get("invoiceGenerator");
const incomeExpenseSetupRepository =
  repositoryFactory.get("incomeExpenseSetup");

export default {
  namespaced: true,
  state: {
    debit: [],
    debitCounter: [],
    credit: [],
    creditCounter: [],
    debitTransactionTypes: [],
    creditTransactionTypes: [],
  },
  mutations: {
    [READ_FEE_SETUP_COLLECTION](state, data) {
      state.debit = data.debit;
      state.debitCounter = data.debitCounter;
      state.credit = data.credit;
      state.creditCounter = data.creditCounter;
      state.debitTransactionTypes = data.debitTransactionTypes;
      state.creditTransactionTypes = data.creditTransactionTypes;
    },
    [CREATE_FEE_SETUP_COLLECTION]() {},
    [UPDATE_FEE_SETUP_DEBIT_COLLECTION](state, data) {
      state.debit = data;
    },
    [UPDATE_FEE_SETUP_DEBIT_COUNTER](state, data) {
      state.debitCounter = data;
    },
    [UPDATE_FEE_SETUP_CREDIT_COLLECTION](state, data) {
      state.credit = data;
    },
    [UPDATE_FEE_SETUP_CREDIT_COUNTER](state, data) {
      state.creditCounter = data;
    },
    [RESET_SETUP_FEE](state) {
      state.debit = [];
      state.debitCounter = [];
      state.credit = [];
      state.creditCounter = [];
    },
  },
  getters: {
    getCollection: (state) => {
      return state;
    },
    getDebit: (state) => {
      return state.debit;
    },
    getDebitCounter: (state) => {
      return state.debitCounter;
    },
    getDebitCounterLength: (state) => {
      return state.debitCounter.length;
    },
    getCredit: (state) => {
      return state.credit;
    },
    getCreditCounter: (state) => {
      return state.creditCounter;
    },
    getCreditCounterLength: (state) => {
      return state.creditCounter.length;
    },
    getDebitTransactionTypes: (state) => {
      return state.debitTransactionTypes;
    },
    getCreditTransactionTypes: (state) => {
      return state.creditTransactionTypes;
    },
    getDebitTransactionTypesLength: (state) => {
      return state.debitTransactionTypes.length;
    },
    getCreditTransactionTypesLength: (state) => {
      return state.creditTransactionTypes.length;
    },
  },
  actions: {
    async readCollection({ commit }, invoiceGeneratorId) {
      commit(RESET_SETUP_FEE);

      let debit = [];
      let debitCounter = [];
      let credit = [];
      let creditCounter = [];

      const collection = await incomeExpenseSetupRepository.readCollection(
        invoiceGeneratorId
      );

      const debitTransactionTypes = collection.debitType.edges;
      const creditTransactionTypes = collection.creditType.edges;

      let i = 0;
      collection.debit.edges.forEach((nodes) => {
        debit.push({
          id: nodes.node.id,
          _id: nodes.node._id,
          transactionType: nodes.node.transactionType._id,
          influence: nodes.node.influence,
        });
        debitCounter.push(i++);
      });
      i = 0;
      collection.credit.edges.forEach((nodes) => {
        credit.push({
          id: nodes.node.id,
          _id: nodes.node._id,
          transactionType: nodes.node.transactionType._id,
          influence: nodes.node.influence,
        });
        creditCounter.push(i++);
      });

      commit(READ_FEE_SETUP_COLLECTION, {
        debit,
        debitCounter,
        credit,
        creditCounter,
        debitTransactionTypes,
        creditTransactionTypes,
      });
    },
    async createCollection({ commit, state }, { invoiceGeneratorIriId }) {
      commit(CREATE_FEE_SETUP_COLLECTION);

      await invoiceGeneratorRepository.update({
        id: invoiceGeneratorIriId,
        incomeExpenseSetups: [],
      });

      for (const debit of state.debit) {
        incomeExpenseSetupRepository.createCollection({
          invoiceGenerator: invoiceGeneratorIriId,
          transactionType: debit.transactionType,
          influence: debit.influence,
        });
      }

      for (const credit of state.credit) {
        incomeExpenseSetupRepository.createCollection({
          invoiceGenerator: invoiceGeneratorIriId,
          transactionType: credit.transactionType,
          influence: credit.influence,
        });
      }

      commit(RESET_SETUP_FEE);
    },
    async updateDebit({ commit }, data) {
      commit(UPDATE_FEE_SETUP_DEBIT_COLLECTION, data);
    },
    async updateDebitCounter({ commit }, data) {
      commit(UPDATE_FEE_SETUP_DEBIT_COUNTER, data);
    },
    async updateCredit({ commit }, data) {
      commit(UPDATE_FEE_SETUP_CREDIT_COLLECTION, data);
    },
    async updateCreditCounter({ commit }, data) {
      commit(UPDATE_FEE_SETUP_CREDIT_COUNTER, data);
    },
    async reset({ commit }) {
      commit(RESET_SETUP_FEE);
    },
  },
};
