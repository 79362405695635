export default [
  {
    name: "Home",
    path: "/",
    meta: {
      layout: "Plain",
    },
    component: () => import("../Pages/Index"),
  },
];
