import {
  CREATE_FEE_SETUP_COLLECTION,
  READ_FEE_SETUP_COLLECTION,
  RESET_SETUP_FEE,
  UPDATE_FEE_SETUP_FEE_COLLECTION,
  UPDATE_FEE_SETUP_FEE_COUNTER,
} from "./mutation";

import { repositoryFactory } from "@/core/api/repositoryFactory";

const invoiceGeneratorRepository = repositoryFactory.get("invoiceGenerator");
const feeSetupRepository = repositoryFactory.get("feeSetup");

export default {
  namespaced: true,
  state: {
    fee: [],
    feeCounter: [],
    transactionType: [],
  },
  mutations: {
    [READ_FEE_SETUP_COLLECTION](state, data) {
      state.fee = data.fee;
      state.feeCounter = data.feeCounter;
      state.transactionType = data.transactionType;
    },
    [CREATE_FEE_SETUP_COLLECTION]() {},
    [UPDATE_FEE_SETUP_FEE_COLLECTION](state, data) {
      state.fee = data;
    },
    [UPDATE_FEE_SETUP_FEE_COUNTER](state, data) {
      state.feeCounter = data;
    },
    [RESET_SETUP_FEE](state) {
      state.fee = [];
      state.feeCounter = [];
      state.transactionType = [];
    },
  },
  getters: {
    getCollection: (state) => {
      return state;
    },
    getFee: (state) => {
      return state.fee;
    },
    getFeeCounter: (state) => {
      return state.feeCounter;
    },
    getFeeCounterLength: (state) => {
      return state.feeCounter.length;
    },
    getTransactionTypes: (state) => {
      return state.transactionType;
    },
    getTransactionTypesLength: (state) => {
      return state.transactionType.length;
    },
  },
  actions: {
    async readCollection({ commit }, invoiceGeneratorId) {
      let fee = [];
      let feeCounter = [];

      commit(RESET_SETUP_FEE);

      const collection = await feeSetupRepository.readCollection(
        invoiceGeneratorId
      );

      const transactionType = collection.transactionType.edges;

      let i = 0;
      collection.fee.edges.forEach((nodes) => {
        fee.push({
          id: nodes.node.id,
          _id: nodes.node._id,
          amount: nodes.node.amount,
          approvedFeeAfter: nodes.node.approvedFeeAfter,
          declinedFeeAfter: nodes.node.declinedFeeAfter,
          approvedFeePercentAfter: nodes.node.approvedFeePercentAfter,
          approvedFee: nodes.node.approvedFee,
          declinedFee: nodes.node.declinedFee,
          approvedFeePercent: nodes.node.approvedFeePercent,
          transactionType: nodes.node.transactionType._id,
          influence: nodes.node.influence,
        });
        feeCounter.push(i++);
      });

      commit(READ_FEE_SETUP_COLLECTION, {
        fee: Object.freeze(fee),
        feeCounter,
        transactionType: Object.freeze(transactionType),
      });
    },
    async createCollection({ commit, state }, { invoiceGeneratorIriId }) {
      commit(CREATE_FEE_SETUP_COLLECTION);

      await invoiceGeneratorRepository.update({
        id: invoiceGeneratorIriId,
        feeSetups: [],
      });

      for (const fee of state.fee) {
        feeSetupRepository.createCollection({
          invoiceGenerator: invoiceGeneratorIriId,
          transactionType: fee.transactionType,
          influence: fee.influence,
          approvedFee: fee.approvedFee,
          declinedFee: fee.declinedFee,
          approvedFeePercent: fee.approvedFeePercent,
          amount: fee.amount,
          approvedFeeAfter: fee.approvedFeeAfter,
          declinedFeeAfter: fee.declinedFeeAfter,
          approvedFeePercentAfter: fee.approvedFeePercentAfter,
        });
      }

      commit(RESET_SETUP_FEE);
    },
    async updateFee({ commit }, data) {
      commit(UPDATE_FEE_SETUP_FEE_COLLECTION, data);
    },
    async updateFeeCounter({ commit }, data) {
      commit(UPDATE_FEE_SETUP_FEE_COUNTER, data);
    },
    async reset({ commit }) {
      commit(RESET_SETUP_FEE);
    },
  },
};
